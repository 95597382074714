import { IAppConfig } from "@codewise/voluum-frontend-core/app";
import { DataLayerPayload } from "@voluum-panel/assistance/ng-free";
import { startWith } from "rxjs/operators";

import { CLIENT_ID_QUERY_PARAM_NAME } from "../globals";
import { SessionManager } from "../module/session/service/manager";
import { EventBus } from "./event_bus";
import { INavigationBuilder, Navigation } from "./navigation";

interface Route {
    fullPath: string;
    hash: string | (() => string);
    checker?: string;
    query?: Record<string, unknown>;
}

export class Router {
    private routes: Record<string, Route>;

    constructor(
        private eventBus: EventBus,
        appConfig: IAppConfig,
        private sessionManager: SessionManager
    ) {
        this.trackRouteChange();

        eventBus.onActivateRoute.subscribe(this.activateRoute.bind(this));
        Navigation.onHashChange.subscribe(this.propagateRoute.bind(this));
        this.routes = {
            tracker: {
                fullPath: appConfig.frontend.tracker,
                hash: "/",
            },
            "tracker.settings": {
                fullPath: appConfig.frontend.tracker,
                hash: "/settings/general",
                checker: "/settings/",
            },
            "tracker.settings.eventLog": {
                fullPath: appConfig.frontend.tracker,
                hash: () =>
                    `/${this.sessionManager.session?.currentClient.id}/settings/event-log`,
            },
            "tracker.settings.domains": {
                fullPath: appConfig.frontend.tracker,
                hash: "/settings/domains",
                checker: "/settings/",
            },
            "tracker.settings.multiuser": {
                fullPath: appConfig.frontend.tracker,
                hash: "/settings/multiusers-management/workspaces",
                checker: "/settings/",
            },
            "tracker.settings.multiuser.users": {
                fullPath: appConfig.frontend.tracker,
                hash: "/settings/multiusers-management/users",
                checker: "/settings/",
            },
            "tracker.settings.sharedReports": {
                fullPath: appConfig.frontend.tracker,
                hash: "/settings/multiusers-management/shared_reports",
                checker: "/settings/",
            },
            "tracker.settings.referralProgram": {
                fullPath: appConfig.frontend.tracker,
                hash: "/settings/referral-program",
                checker: "/settings/",
            },
            "tracker.advanced.advancedReports": {
                fullPath: appConfig.frontend.tracker,
                hash: "/advanced/advanced-reports",
                checker: "/advanced/",
            },
            "tracker.advanced.afk": {
                fullPath: appConfig.frontend.tracker,
                hash: "/advanced/afk",
                checker: "/advanced/",
            },
            "tracker.notifications": {
                fullPath: appConfig.frontend.tracker,
                hash: "/notifications/",
                checker: "/notifications/",
            },
            "tracker.features": {
                fullPath: appConfig.frontend.tracker,
                hash: "/features/",
                checker: "/features/",
            },
            "tracker.automizer": {
                fullPath: appConfig.frontend.tracker,
                hash: "/automizer",
                checker: "/automizer",
            },
        };
        this.propagateRoute();
    }

    private activateRoute(routeName: string): void {
        const route = this.routes[routeName];
        const navigationBuilder: INavigationBuilder = Navigation.builder()
            .withUrl(route.fullPath)
            .withQuery(this.decorateQuery(route.query));
        if (route.hash) {
            const hash: string =
                route.hash instanceof Function ? route.hash() : route.hash;
            navigationBuilder.withHash(hash);
        }
        navigationBuilder.assign();
    }

    private decorateQuery(query: Route["query"] = {}): Route["query"] {
        query[CLIENT_ID_QUERY_PARAM_NAME] =
            this.sessionManager.session.currentClient.id;
        return query;
    }

    private propagateRoute(): void {
        const currentFullPath: string =
            window.location.origin + window.location.pathname;
        const currentRoute = Object.keys(this.routes)
            .filter(
                (routeName: string) =>
                    this.routes[routeName].fullPath === currentFullPath
            )
            .filter((routeName: string) => {
                const hash =
                    this.routes[routeName].hash instanceof Function
                        ? (this.routes[routeName].hash as () => string)()
                        : (this.routes[routeName].hash as string);
                return new RegExp(this.routes[routeName].checker || hash).test(
                    window.location.hash
                );
            })
            .pop();
        if (currentRoute) {
            this.eventBus.activateRoute(currentRoute);
        }
    }

    /*
     * This method is used to track route changes for Google Analytics
     */
    private trackRouteChange(): void {
        Navigation.onHashChange
            .pipe(startWith(window.location.hash))
            .subscribe((hash) => {
                // Remove query params and # parts
                const paths: string[] = hash.split("?")[0].split("/").slice(1);
                const dataLayerPayload: { [key: string]: string } =
                    paths.reduce(function (
                        acc: { [key: string]: string },
                        current: string,
                        index: number
                    ) {
                        acc["navigation_path_" + (index + 1)] = current;
                        return acc;
                    }, {});

                this.eventBus.updateDataLayer(
                    dataLayerPayload as DataLayerPayload
                );
            });
    }
}
