import {
    IStorage,
    LocalStorage,
} from "@codewise/voluum-frontend-framework/storage";
import {
    SIDENAV_MODE_BUCKET,
    SIDENAV_MODE_KEY,
    SIDENAV_MODE_NAMESPACE,
    SidenavMode,
} from "@voluum-panel/util/ng-free";
import { noop, of } from "rxjs";
import { catchError, take } from "rxjs/operators";

export class SideNavWidthService {
    private static readonly _sidenavModeStorage: IStorage<
        string,
        string,
        SidenavMode
    > = new LocalStorage(SIDENAV_MODE_NAMESPACE, [SIDENAV_MODE_BUCKET]);
    private static readonly SPACE_HOLDER_SELECTOR: string =
        ".sidenav-space-holder";

    /**
     * Tries to get current mode of side navigation and apply class to DOM
     * element based on it.
     * This DOM element serves as a placeholder for side navigation and holds
     * space for it, to prevent from content jumping (good for CLS metric).
     */
    public static determineMode(): void {
        try {
            SideNavWidthService._sidenavModeStorage
                .getItem(SIDENAV_MODE_BUCKET, SIDENAV_MODE_KEY)
                .pipe(
                    take(1),
                    // in case of error, initialize as expanded
                    catchError(() => of("expanded"))
                )
                .subscribe((mode) => {
                    const spaceHolderEl = document.querySelector(
                        SideNavWidthService.SPACE_HOLDER_SELECTOR
                    );

                    // Apply class to DOM element
                    spaceHolderEl?.classList.add(mode);
                });
        } catch (_err) {
            // Ignore error
            noop();
        }
    }
}
