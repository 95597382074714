import {
    DataLayerPayload,
    PromoSummary,
} from "@voluum-panel/assistance/ng-free";
import {
    FeatureFlag,
    Plan,
    Session,
    UserPreferenceModel,
} from "@voluum-panel/profile/ng-free";

import { Ajax } from "../../../service/ajax";
import { EventBus } from "../../../service/event_bus";

export class TagManager {
    constructor(
        key: string,
        eventBus: EventBus,
        session: Session,
        plan: Plan,
        promoSummary: PromoSummary,
        _: UserPreferenceModel
    ) {
        require("./tag_manager_core.js")(key);

        const context: Window & { dataLayer?: unknown[] } = window;
        const initialData: Record<string, unknown> = {
            event: "initialDataLayerData",
            client_ID: session.user.defaultClient.id,
            user_ID: session.user.id,
            client_plan: plan.code,
            user_status: session.user.state,
            first_registration: session.user.creationTime,
            sidenav: true,
            dsp_vertical_markets:
                promoSummary.hubspotProperties.dsp_vertical_markets,
            ai_driven_dominant_vertical:
                promoSummary.hubspotProperties.ai_driven_dominant_vertical,
            ai_driven_business_profile:
                promoSummary.hubspotProperties.ai_driven_business_profile,
            trk_genai_offer_vertical_labels:
                promoSummary.hubspotProperties.trk_genai_offer_vertical_labels,
            trk_survey_user_profile:
                promoSummary.hubspotProperties.trk_survey_user_profile,
            trk_survey_business_niche:
                promoSummary.hubspotProperties.trk_survey_business_niche,
            ai_drive_lead_generation_flag:
                promoSummary.hubspotProperties.ai_drive_lead_generation_flag,
            trk_owner: promoSummary.hubspotProperties.trk_owner,
            quickstart: session.currentClient.hasToggle(FeatureFlag.WIZARD),
            "AI nav": session.currentClient.hasToggle(FeatureFlag.AI_NAV),
        };

        this.getEEAStatus()
            .then(
                (response): void => {
                    initialData.b9e1a58e5807 = response.b9e1a58e5807;
                },
                (): void => {
                    initialData.b9e1a58e5807 = 2;
                }
            )
            .then((): void => {
                context.dataLayer?.push(initialData);
            });

        eventBus.onUpdateDataLayer.subscribe(
            (dataLayerData: DataLayerPayload) => {
                dataLayerData.event = dataLayerData.event || "updateDataLayer";
                dataLayerData.eventLabel =
                    dataLayerData.eventLabel || undefined;
                dataLayerData.eventValue =
                    dataLayerData.eventValue || undefined;
                context.dataLayer?.push(dataLayerData);
            }
        );
    }

    private getEEAStatus(): Promise<{ b9e1a58e5807: number }> {
        return Ajax.get("https://1706.codewise.com") as Promise<{
            b9e1a58e5807: number;
        }>;
    }
}
