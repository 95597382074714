import { __decorate, __metadata } from "tslib";
import { AutoSerialize } from "@codewise/voluum-frontend-framework/serialization";
export class ColumnVisibility {
}
__decorate([
    AutoSerialize(),
    __metadata("design:type", String)
], ColumnVisibility.prototype, "name", void 0);
__decorate([
    AutoSerialize(),
    __metadata("design:type", Boolean)
], ColumnVisibility.prototype, "visible", void 0);
