import {
    IHttpService,
    IRequestConfig,
} from "@codewise/voluum-frontend-framework/api";
import { Observable } from "rxjs";
import { ajax, AjaxConfig, AjaxResponse } from "rxjs/ajax";
import { map } from "rxjs/operators";

import {
    AUTHENTICATION_HEADER_NAME,
    AUTHENTICATION_PANEL_HEADER_NAME,
} from "../../../../globals";
import {
    Authentication,
    encodeToken,
} from "../../../../service/authentication";

export const simpleHttpService: IHttpService = {
    request: (
        method: string,
        url: string,
        options?: IRequestConfig
    ): Observable<unknown> =>
        ajax({
            method,
            ...(options as Partial<AjaxConfig>),
            headers: {
                [AUTHENTICATION_HEADER_NAME]: Authentication.getToken(),
                [AUTHENTICATION_PANEL_HEADER_NAME]: encodeToken(
                    Authentication.getToken()
                ),
                "Content-Type": "application/json",
            },
            url,
        }).pipe(
            map((ajaxResponse: AjaxResponse<unknown>) => ({
                ...ajaxResponse,
                body: ajaxResponse.response,
                headers: {
                    get(): void {},
                },
            }))
        ),
};
