/**
 * Lists all the feature flags that client can have enabled or disabled
 */
export enum FeatureFlag {
    AFK_LANDER_SCRIPT = "afk-lander-script",
    ASYNC_REPORTS = "async-reports",
    AUTO_OPTIMIZATION_PATHS = "auto-optimization-paths",
    BETA_TESTING = "beta-testing",
    /**
     * @deprecated use {@link IntegrationModel#hideToggle}
     */
    BING_TS_INTEGRATION = "bing-ts-integration",
    CHART_METRICS_SELECTOR = "chart-metrics-selector",
    CLOUDFRONT_DISTRIBUTION = "cloudfrontDistribution",
    COMPARISON_COLUMN = "comparison-column",
    CONVERSION_REPORT_GROUPINGS = "conversion-report-groupings",
    CUSTOM_COLUMNS = "custom-columns",
    DOMAIN_WORKSPACES = "domain-workspaces",
    EXPORT_SKU_REPORT = "export-sku-report",
    IMPRESSION_PIXEL_FORWARDING = "impression-pixel-forwarding",
    INVALID_TRAFFIC_DETECTION = "invalid-traffic-detection",
    LANDER_PROTECTION = "lander-protection",
    LISTICLE_TRACKING = "listicle-tracking",
    MULTI_GROUPING = "multi-grouping",
    NOTIFICATION_CUSTOM_BY_EMAIL = "notification-custom-by-email",
    READONLY_MODE_ENABLED = "readonly-mode-enabled",
    REJECT_VISITS_FOR_ARCHIVED_CAMPAIGNS = "rejectVisitsForArchivedCampaigns",
    REPORTS_SHOW_UNIQUE_CLICKS = "reports-show-unique-clicks",
    REQUEST_PARAMETERS_FILTERING = "requestParametersFiltering",
    SCHEDULED_REPORTS = "scheduledReports",
    SHARED_REPORTS = "shared-reports",
    /**
     * @deprecated use {@link IntegrationModel#hideToggle}
     */
    SHOPIFY_TS_INTEGRATION = "shopify-ts-integration",
    /**
     * @deprecated use {@link IntegrationModel#hideToggle}
     */
    SHOPIFY_TS_INTEGRATION_BETA = "shopify-ts-integration-beta",
    SHOW_MEAN_TIME_TO_INSTALL = "show-mean-time-to-install",
    TIKTOK_TS_INTEGRATION = "tiktok-ts-integration",
    /**
     * @deprecated use {@link IntegrationModel#hideToggle}
     */
    TIKTOK_TS_INTEGRATION_BETA = "tiktok-ts-integration-beta",
    TRAFFIC_LOG = "traffic-log",
    WANDUUM_ENABLED = "wanduum-enabled",
    WIZARD = "wizard",
    INTEGRATION_CONVERSION_ENABLED = "glassConversionUploadToTrafficSource",
    IMPRESSION_TRACKING = "impressionTracking",
    AI_NAV = "aiNavigation",
    EXP_TAGS_COLUMN = "expTagsColumn",
    QS_INLINE_LANDER_ADD = "qsInlineLanderAdd",
    LAST_SEARCH_LIST_ENABLED = "lastSearchListEnabled",
}
