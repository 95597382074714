import {
    AutoSerialize,
    AutoSerializeCollection,
} from "@codewise/voluum-frontend-framework/serialization";

import { ColumnVisibility } from "./column-visibility.model";

export class ChartPreferences {
    get selectedColumnNames(): string[] {
        return this.selectedColumns.map((column) => column.name);
    }

    set selectedColumnNames(selectedColumnNames: string[]) {
        this.selectedColumns = selectedColumnNames.map((name) => {
            const alreadySelected = this.selectedColumns.find(
                (selectedColumn) => selectedColumn.name === name
            );
            return {
                name,
                visible: alreadySelected ? alreadySelected.visible : true,
            };
        });
    }

    get visibleColumnNames(): string[] {
        return this.selectedColumns
            .filter((column) => column.visible)
            .map((column) => column.name);
    }

    @AutoSerializeCollection(ColumnVisibility)
    public selectedColumns: ColumnVisibility[] = [];

    @AutoSerialize("showsPreviousTimeRange")
    public showPreviousTimeRange: boolean = false;
}
