import { ContactWidget } from "@codewise/freshdesk-contact-widget";
import { IAppConfig } from "@codewise/voluum-frontend-core/app";
import { NotificationSubtypeEnum } from "@codewise/voluum-frontend-core/events";
import { IConfirmableEvent } from "@codewise/voluum-frontend-framework/event_bus_client";
import { IFreshdeskWidgetConfig } from "@voluum-panel/assistance/ng-free";
import { Session } from "@voluum-panel/profile/ng-free";

import { EventBus } from "../../../service/event_bus";

class Freshdesk {
    constructor(eventBus: EventBus, session: Session, appConfig: IAppConfig) {
        eventBus.onOpenSupportPopup.subscribe((event: IConfirmableEvent) => {
            const eventData: IFreshdeskWidgetConfig = event.data;
            const emailConfig: { email: string; email_disabled: boolean } =
                session.user.isDemoAccount()
                    ? { email: "", email_disabled: false }
                    : { email: session.user.email, email_disabled: true };

            const data = {
                ...emailConfig,
                subject: eventData.data.subject,
                subject_disabled: !!eventData.data.subject,
                link: eventData.data.link,
                feedback: eventData.data.feedback,
                message_placeholder: eventData.data.messagePlaceholder,
                message: eventData.data.message,
                message_disabled: eventData.data.messageDisabled,
                additional_message: eventData.data.additionalMessage,
                show_tip: eventData.data.showTip,
            };

            const tags: string[] = eventData.freshdesk.tags || [];

            if (!tags.includes("send-to-sales")) {
                tags.push("send-to-cs");
            }

            if (appConfig.environment !== "prod") {
                tags.push("TEST");
            }

            const freshdesk = {
                api_key: eventData.freshdesk.apiKey,
                api_pass: eventData.freshdesk.apiPass,
                email_config_id: eventData.freshdesk.emailConfigId,
                group_id: eventData.freshdesk.groupId,
                priority: eventData.freshdesk.priority,
                product_id: eventData.freshdesk.productId,
                source: eventData.freshdesk.sourceId,
                status: eventData.freshdesk.status,
                tags: tags,
                authorized: true,
            };

            ContactWidget.show(data, freshdesk).then(
                () => {
                    eventBus.showToastNotification({
                        subType: NotificationSubtypeEnum.SUCCESS,
                        message:
                            "Thanks for getting in touch with the Voluum Team.",
                        closeDelay: 10000,
                    });

                    event.confirm?.();
                },
                () => {
                    event.deny?.();
                }
            );
        });
    }
}

export { Freshdesk };
