import { LocalStorage, } from "@codewise/voluum-frontend-framework/storage";
import { SIDENAV_MODE_BUCKET, SIDENAV_MODE_KEY, SIDENAV_MODE_NAMESPACE, } from "@voluum-panel/util/ng-free";
import { noop, of } from "rxjs";
import { catchError, take } from "rxjs/operators";
export class SideNavWidthService {
    static determineMode() {
        try {
            SideNavWidthService._sidenavModeStorage
                .getItem(SIDENAV_MODE_BUCKET, SIDENAV_MODE_KEY)
                .pipe(take(1), catchError(() => of("expanded")))
                .subscribe((mode) => {
                const spaceHolderEl = document.querySelector(SideNavWidthService.SPACE_HOLDER_SELECTOR);
                spaceHolderEl === null || spaceHolderEl === void 0 ? void 0 : spaceHolderEl.classList.add(mode);
            });
        }
        catch (_err) {
            noop();
        }
    }
}
SideNavWidthService._sidenavModeStorage = new LocalStorage(SIDENAV_MODE_NAMESPACE, [SIDENAV_MODE_BUCKET]);
SideNavWidthService.SPACE_HOLDER_SELECTOR = ".sidenav-space-holder";
