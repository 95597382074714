import { __decorate, __metadata } from "tslib";
import { AutoSerialize, AutoSerializeCollection, } from "@codewise/voluum-frontend-framework/serialization";
import { ColumnVisibility } from "./column-visibility.model";
export class ChartPreferences {
    constructor() {
        this.selectedColumns = [];
        this.showPreviousTimeRange = false;
    }
    get selectedColumnNames() {
        return this.selectedColumns.map((column) => column.name);
    }
    set selectedColumnNames(selectedColumnNames) {
        this.selectedColumns = selectedColumnNames.map((name) => {
            const alreadySelected = this.selectedColumns.find((selectedColumn) => selectedColumn.name === name);
            return {
                name,
                visible: alreadySelected ? alreadySelected.visible : true,
            };
        });
    }
    get visibleColumnNames() {
        return this.selectedColumns
            .filter((column) => column.visible)
            .map((column) => column.name);
    }
}
__decorate([
    AutoSerializeCollection(ColumnVisibility),
    __metadata("design:type", Array)
], ChartPreferences.prototype, "selectedColumns", void 0);
__decorate([
    AutoSerialize("showsPreviousTimeRange"),
    __metadata("design:type", Boolean)
], ChartPreferences.prototype, "showPreviousTimeRange", void 0);
