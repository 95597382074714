import {
    ReferentiallyImmutable,
    ReferentiallyImmutableKey,
    Shared,
    SharedProperty,
    SharedPropertyObserver,
    SharedPropertyUpdateHook,
} from "@codewise/voluum-frontend-framework/model";
import { BehaviorSubject, Observable } from "rxjs";

import { Client } from "../../client/model";
import { IMembershipJSON, Membership } from "./membership.model";
import type { MultiuserMigrationStatus } from "./multiuser-migration-status.type";

export type UserRole =
    | "ROLE_ADMIN"
    | "ROLE_CLIENT_ADMIN"
    | "ROLE_USER"
    | "ROLE_DEMO";
export type UserState =
    | "NEW"
    | "INACTIVE"
    | "ACTIVATED"
    | "SUSPENDED"
    | "DELETED";

@ReferentiallyImmutable()
@Shared("User")
export class User {
    @ReferentiallyImmutableKey()
    public readonly id: string;

    public readonly memberships: Membership[];
    public readonly defaultClientMembership: Membership | undefined;
    public readonly defaultClient: Client;

    public isFirstLogin: boolean = false;

    @SharedProperty()
    public multiuserStatus: MultiuserMigrationStatus | undefined;
    @SharedProperty()
    public firstName: string;

    @SharedProperty()
    public lastName: string;

    @SharedProperty()
    public email: string;

    @SharedProperty()
    public currency: string;

    @SharedProperty()
    public experimentalGroup: boolean;

    @SharedProperty()
    public timeZone: string;

    @SharedPropertyObserver("multiuserStatus")
    public readonly multiuserStatus$: Observable<
        MultiuserMigrationStatus | undefined
    >;

    @SharedPropertyObserver("currency")
    public currency$: Observable<string>;

    public readonly fullName$: Observable<string>;

    get fullName(): string {
        return `${this.firstName} ${this.lastName}`;
    }

    @SharedPropertyObserver("timeZone")
    public timeZone$: Observable<string>;

    @SharedPropertyObserver("email")
    public email$: Observable<string>;

    public static deserializer(userData: IUserJSON): User {
        return new User(
            userData.id,
            userData.firstName,
            userData.lastName,
            userData.email,
            userData.role,
            userData.created,
            userData.state,
            userData.timezone,
            userData.currency,
            userData.defaultClientId,
            userData.memberships,
            userData.preferredClientId,
            userData.experimentalGroup
        );
    }

    constructor(
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        public readonly role: UserRole,
        public readonly creationTime: string,
        public readonly state: UserState,
        timeZone: string,
        currency: string,
        defaultClientId: string,
        memberships: IMembershipJSON[] = [],
        preferredClientId: string | undefined,
        experimentalGroup: boolean
    ) {
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.fullName$ = new BehaviorSubject<string>(this.fullName);
        this.email = email;
        this.email$ = new BehaviorSubject<string>(this.email);
        this.timeZone = timeZone;
        this.timeZone$ = new BehaviorSubject<string>(this.timeZone);
        this.currency = currency || "USD";
        this.currency$ = new BehaviorSubject<string>(this.currency);
        this.memberships = memberships.map(Membership.deserializer);
        this.defaultClient = this.chooseDefaultClient(
            preferredClientId,
            defaultClientId
        );
        this.defaultClientMembership = this.memberships.find(
            (membership) => membership.client === this.defaultClient
        );
        this.experimentalGroup = experimentalGroup;

        this.multiuserStatus$ = new BehaviorSubject<
            MultiuserMigrationStatus | undefined
        >(this.multiuserStatus);
    }

    public isDemoAccount(): boolean {
        return this.role === "ROLE_DEMO";
    }

    public isRoleOwner(clientId: string): boolean {
        return this.memberships.some(
            (membership) =>
                membership.client.id === clientId && membership.role === "OWNER"
        );
    }

    @SharedPropertyUpdateHook()
    protected onSharedPropertyUpdate(property: keyof User): void {
        switch (property) {
            case "firstName":
            case "lastName":
                (this.fullName$ as BehaviorSubject<string>).next(this.fullName);
                break;
        }
    }

    private chooseDefaultClient(
        preferredClientId: string | undefined,
        defaultClientId: string
    ): Client {
        let defaultClient: Client | undefined;
        const clients: Client[] = this.memberships.map(
            (member: Membership) => member.client
        );

        defaultClient = clients.find(
            (client: Client) => client.id === preferredClientId
        );

        if (!defaultClient) {
            defaultClient = clients.find(
                (client: Client) => client.id === defaultClientId
            )!;
        }

        return defaultClient;
    }
}

export interface IUserJSON {
    id: string;
    defaultClientId: string;
    preferredClientId?: string;
    firstName: string;
    lastName: string;
    role: UserRole;
    email: string;
    created: string;
    state: UserState;
    timezone: string;
    currency: string;
    memberships: IMembershipJSON[];
    experimentalGroup: boolean;
}
